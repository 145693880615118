"use strict";

var $ = require("jquery");
var moment = require("moment");


function MakeupsPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

function generate_linked_cell(text, href) {
	return $("<td/>").append(
		$("<a/>").attr("href", href).text(
			fix_html_ents(text)
		)
	);
}

MakeupsPage.prototype.load = function(cb) {
	var api_user = this.state.frontend.state.api_user;

	api_user.fetch_data("/student/attendance/get_makeup_all", null, function(err, makeups) {

		console.log(makeups);


		if (makeups.length < 1) {
			$(".msg-no-results").removeClass("hide");
		} else {
			for (let makeup of makeups) {
				let tr = $("<tr/>");
				var target = "/student/lesson_detail?lesson=" + makeup.madeup_id;

				// let date = new Date(makeup.cl_realdate);

				[
					makeup.skipped_name,
					// moment(makeup.skipped_date).format("DD/MM/YYYY HH:mm"),
					makeup.skipped_date_fmt,
					makeup.madeup_name,
					// moment(makeup.madeup_date).format("DD/MM/YYYY HH:mm"),
					makeup.madeup_date_fmt,
					makeup.remark,
					// moment(makeup.date_added).format("DD/MM/YYYY")
					makeup.date_added_fmt
				].forEach(to_add => {
					$(tr).append(generate_linked_cell(to_add, target));
				});

				$("#makeups-table tbody").append(tr);
			}
		}

		cb();

		api_user.fetch_data("/student/course/get_skippable_lessons", null, function(err, skippable) {
			if (skippable.lessons.length > 0) {
				$("#add_makeup_btn").removeClass("hide2");
			}
			cb();
		});
		
	});




};

module.exports = MakeupsPage;