"use strict";

var $ = require("jquery");
var common = require("../../common");

var moment = require("moment");
var BootstrapDialog = require("bootstrap3-dialog");



function LessonsPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

 LessonsPage.prototype.generate_linked_cell = function (text, href, is_expired, is_withdrawn, add_message_if_is_expired, is_makeup_skip_mode) {

	let a = $("<a/>").attr("href", href).text(text);
	if (is_makeup_skip_mode && (is_expired || is_withdrawn)) {
		$(a).attr("href", "#");
		$(a).click(e => {
			e.preventDefault();


			BootstrapDialog.show({
				message: "The course has expired. Make-up for this lesson is no longer available.",
				title: "Cannot Make-Up",
				cssClass: "very-high-zindex",
				closable: true,
				type: BootstrapDialog.TYPE_INFO,
				buttons: [
					{
						"label": "OK",
						action: function(dialogItself){
							dialogItself.close();
						}
					}
				]}
			);


		});
	}
	if (is_expired) {
		$(a).addClass("expired-link");
	}
	let td = $("<td/>").append(a)

	if (is_withdrawn && add_message_if_is_expired) {
		let expired = $("<span/>").text(this.state.frontend.get_string("withdrawn")).addClass("expired-note"); 
		$(td).append(expired);
	} else if (is_expired && add_message_if_is_expired) {
		let expired = $("<span/>").text(this.state.frontend.get_string("expired")).addClass("expired-note"); 
		$(td).append(expired);
	}


	return td;
};




var month_names = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

LessonsPage.prototype.apply_filters = function() {
	var filters = [];
	var critera = {};

	$("[data-lessons-filter]").each((_, f) => {
		console.log(f);
		filters.push({
			name: $(f).attr("data-lessons-filter"),
			val:  $(f).val()
		});
	});


	for (let filter of filters) {
		if (filter && filter.val != "") {
			critera[filter.name] = filter.val;
		}
	}

	console.log(critera);

	var rows = $("#lessons-table tbody tr");
	for (let critera_name in critera) {
		rows = rows.filter("[data-filterable-" + critera_name + "=\"" + critera[critera_name] + "\"]");
	}

	if ($(rows).length < 1) {
		console.log("No results");
		$(".msg-no-results").removeClass("hide");
	} else {
		$(".msg-no-results").addClass("hide");
	}

	$(rows).css("display", "");

	let total_attachment_count = 0;

	$(rows).each((_,row) => {
		let attachments_cell = $(row).find(".attachments_cell");
		total_attachment_count += ($(attachments_cell).find("a").length);
	});

	console.log(total_attachment_count);

	// TODO: Don't use hardcoded indices for this
	if (total_attachment_count > 0) {
		$("td:nth-of-type(6), th:nth-of-type(6)").css("display", "");
	} else {
		$("td:nth-of-type(6), th:nth-of-type(6)").css("display", "none");
	}

	$("#lessons-table tbody tr").not(rows).css("display", "none");

	console.log(rows);
};


LessonsPage.prototype.load = async function(cb) {
	var api_user = this.state.frontend.state.api_user;

	$(".course-search-loading-bg").removeClass("hide");

	let enrolled_courses = await api_user.fetch_data_promise(
		"/student/course/get_enrolled_courses",
		null
	);

	console.log(enrolled_courses);

	var args = common.get_args();

	var course_arg = args["course"];
	var mode_arg = args["mode"];
	var lesson_from_arg = args["lesson_from"];

	let have_all_expired = args["expired"];

	let is_add_makeup_from_mode = (mode_arg && mode_arg == "add_makeup_from");
	let is_add_makeup_to_mode   = (mode_arg && mode_arg == "add_makeup_to" && lesson_from_arg);

	// Hide videos button if in a makeup mode
	if (is_add_makeup_from_mode || is_add_makeup_to_mode /*|| have_all_expired*/) {
		$("#watch-my-videos").addClass("display-none");
		$("body").addClass("is_makeup_mode");

		$(".day_group,.time_group").removeClass("hide");
	}

	let title = "";
	if (is_add_makeup_from_mode) {
		title = this.state.frontend.get_nss_from_sid("101:title_makeup_choose_skip");
	} else if (is_add_makeup_to_mode) {
		title = this.state.frontend.get_nss_from_sid("101:title_makeup_choose_makeup");
	} else {
		title = this.state.frontend.get_nss_from_sid("101:Lessons");
	}

	$(".page-title").text(title);

	if (have_all_expired) {
		$(".page-title").append($("<span/>").text(this.state.frontend.get_string("expired")).addClass("expired-note"));
	}

	var current_my_vids_url = $("#watch-my-videos").attr("href");
	if (course_arg) {
		current_my_vids_url = "/jdp?page=my_videos#%7B%22stage%22%3A%22choose_month%22%2C%22course%22%3A" + course_arg + "%7D";
		$("#watch-my-videos").attr("href", current_my_vids_url);
	}

	var endpoint = "/student/course/";

	if (is_add_makeup_to_mode || is_add_makeup_from_mode) {
		api_user.fetch_data("/student/attendance/get_makeup_info?just_checking=true", null, function(err, res) {
			if (err) {
				console.log(err);
			} else if (res) {
				$("#makeups-info-button").removeClass("hide");
			}
		});
	}

	if (is_add_makeup_from_mode) {
		endpoint += "get_skippable_lessons";
	}
	else if (is_add_makeup_to_mode) {
		endpoint += "get_lesson_makeup_targets?from_lesson=" + lesson_from_arg;
	} else {
		endpoint += "get_lesson_all?include_attachments=true&include_collist=true&include_sessions=true";
	}

	api_user.fetch_data(endpoint, null, (err, res) => {

		if (err) {
			alert("Error fetching information.");
			return;
		}

		let show_fields = null;

		if (res["show_fields"]) {
			show_fields = res["show_fields"];
			res = res["lessons"];
		}

		console.log(res);

		let years = new Set();
		let months = new Set();
		let times = new Set();
		let days = new Set();
		let courses = new Set();
		let course_id_to_course_name = {};

		let today = new Date();
		let today_month = moment().format("MMMM");
		let today_year = today.getFullYear();

		if (res.length < 1) {
			console.log("no results");

			if (common.get_args()["from_login"]) {
				window.location = "/student/profile";
				return;
			}

			$(".course-search-loading-bg").addClass("hide");

			this.apply_filters();
			return;
		}


		let _css = "";
		console.log(show_fields);

		let _col_num = 1;
		let addCol = (str_name, should_override_condition, overridden_condition) => {
			let th = $("<th/>");
			let str = nss("104:label_lesson_list_column_" + str_name);
			$(th).text(str);
			let visible = !!show_fields.includes(str_name);

			if (should_override_condition) {
				visible = overridden_condition;
			}

			if (visible) {
				_css += ` @media only screen and (max-width: 1160px), (min-device-width: 768px) and (max-device-width: 1024px)  { #lessons-table td:nth-of-type(${_col_num}):before { content: "${str}" !important; }}`;
			} else {
				console.log("don't display col: " + str_name);
				$(th).css("display", "none");
				_css += ` #lessons-table td:nth-of-type(${_col_num}) { display: none !important; }`;

			}

			$("#lessons-table thead tr").append(th);

			_col_num++;
		};


		$("#lessons-table thead tr").empty();

		addCol("lesson_date");
		addCol("lesson_time");
		addCol("lesson_day");
		
		addCol("course", true, (show_fields.includes("cou_name") || show_fields.includes("cou_code")));
		addCol("branch");


		if (!is_add_makeup_from_mode && !is_add_makeup_to_mode) {
			console.log("not makeup");
			addCol("lesson_attachments");

			addCol("venue");
			addCol("teacher");

			addCol("attendance");
			addCol("clock_in");
			addCol("clock_out");


			addCol("lesson_remarks");
			addCol("student_remarks");
			addCol("notcoming_remarks");
			addCol("teacher_remarks");
		}

		common.add_css(_css);

		for (let lesson of res) {
			if (!lesson.cou_id && course_arg) {
				lesson.cou_id = parseInt(course_arg);
			}

			let tr = $("<tr/>");

			let target = "";
			if (is_add_makeup_from_mode) {
				target = "/student/lessons?mode=add_makeup_to&lesson_from=" + lesson.cl_id;// + "&course=" + lesson.cou_id;
			} else if (is_add_makeup_to_mode) {
				target = "/student/makeup_confirm?lesson_from=" + lesson_from_arg + "&lesson_to=" + lesson.cl_id + "&course=" + lesson.cou_id;

			} else {
				 target = "/student/lesson_detail?lesson=" + lesson.cl_id;
			}

			$(tr).attr("data-filterable-year",   lesson.filterable_year);
			$(tr).attr("data-filterable-month",  lesson.filterable_month);
			$(tr).attr("data-filterable-time",  lesson.formatted_time);
			$(tr).attr("data-filterable-day",  lesson.filterable_day);
			$(tr).attr("data-filterable-course", lesson.cou_id);

			years.add(lesson.filterable_year);
			months.add(lesson.filterable_month_int);
			times.add(lesson.formatted_time);
			days.add(lesson.filterable_day);

			courses.add(lesson.cou_id);

			course_id_to_course_name[lesson.cou_id] = fix_html_ents(lesson.cou_name)
				+ (lesson.cou_code ? (" (" + fix_html_ents(lesson.cou_code) + ")") : "" ) ;

			let itemsAddedCount = 0;

			if (!show_fields.includes("cou_name")) {
				lesson.cou_name = "";
			}

			if (!show_fields.includes("cou_code")) {
				lesson.cou_code = "";
			}

			let to_use = [
				lesson.formatted_date,
				lesson.formatted_time,
				lesson.formatted_dow,
				fix_html_ents(lesson.cou_name) + (lesson.cou_code ? (" (" + fix_html_ents(lesson.cou_code) + ")") : "" ) + (lesson.was_skipped ? " (Skipped)" : ""),
				lesson.branch || "",
			];

			if (!is_add_makeup_from_mode && !is_add_makeup_to_mode) {

				for (let thing of [
					(lesson.attachments || []),
					lesson.venue,
					lesson.teacher,
					lesson.attendance,
					lesson.in_time,
					lesson.out_time,
					lesson.lesson_remarks,
					lesson.student_remarks,
					lesson.notcoming_remarks,
					lesson.teacher_remarks
				]) {
					to_use.push(thing);
				}
			}


			to_use.forEach((to_add, to_add_i) => {
				let cell = null;

				if (to_add_i == 5 /* Attachments */) {
					let td = $("<td/>");
					$(td).addClass("attachments_cell")

					if (to_add.length > 0) {
						let ul = $("<ul/>");

						for (let attachment of to_add) {
							let url = this.state.frontend.state.api_base + "/student/course/download_course_attachment?clid=" + lesson.cl_id + "&file_id=" + attachment.id;
							let _a = $("<a/>").css({"display":"block"}).attr("href", url).text(attachment.name);
							ul.append(_a);
						}

						$(td).append(ul)
					} else {
						$(td).append($("<span/>").addClass("only_mobile_cards").text("(None)"));
					}

					cell = td;

				} else {
					let withdrawn = lesson.is_withdrawn;

					cell = this.generate_linked_cell(to_add, target, lesson.is_expired, withdrawn, (itemsAddedCount == 3), is_add_makeup_from_mode);
				}

				itemsAddedCount++;

				$(tr).append(cell);
			});

			$("#lessons-table tbody").append(tr);
		}

		for (let year of years) {
			let val = year;
			let opt = $("<option/>").val(val).text(val);
			if (val == today_year) {
				$(opt).attr("selected", "selected");
			}

			$("[data-lessons-filter=year]").append(opt);
		}

		var months_sorted_array =
			Array.from(months)
			.map(m => {
				return parseInt(m);
			})
			.sort((a, b) => {
				return a - b;
			})
		;

		for (let month of months_sorted_array) {
			let val = month_names[month];
			let opt = $("<option/>").val(val).text(val);
			if (val == today_month) {
				$(opt).attr("selected", "selected");
			}
			$("[data-lessons-filter=month]").append(opt);
		}

		if (is_add_makeup_from_mode || is_add_makeup_to_mode) {

			for (let time of times) {
				let val = time;
				let opt = $("<option/>").val(val).text(val);
				$("[data-lessons-filter=time]").append(opt);
			}

			let days_sorted = Array.from(days).sort((a,b)=>(a-b));

			console.log(days_sorted);
			let current_date = moment().format("YYYY-MM-DD");
			console.log(current_date);

			let day_to_show = null;

			let future_lessons = res.filter(l=>l.filterable_date >= current_date).sort((a,b) => (a.filterable_date - b.filterable_date));
			if (future_lessons && future_lessons.length > 0) {
				day_to_show = future_lessons[0].filterable_day;
			}

			for (let day of days_sorted) {
				let val = day;
				let text = ""+day;
				if (text.length<2) { text = "0" + text; }
				let opt = $("<option/>").val(val).text(val);
				if (day_to_show && (val == day_to_show)) {
					$(opt).attr("selected", "selected");
				}

				$("[data-lessons-filter=day]").append(opt);
			}
		}

		let have_any_lessons_matching_course = false;
		if (course_arg) {
			let matching = res.filter(r => r.cou_id == parseInt(course_arg));
			if (matching.length > 0) {
				have_any_lessons_matching_course = true;
			}
		}


		for (let course_id of courses) {
			let name = course_id_to_course_name[course_id];

			let elem = $("<option/>");
			$(elem).val(course_id).text(name);

			$("[data-lessons-filter=course]").append(elem);
		}

		this.apply_filters();

		cb();

		$(".course-search-loading-bg").addClass("hide");
	});

	$("[data-lessons-filter]").change(() => {
		this.apply_filters();
	});
};

module.exports = LessonsPage;