"use strict";

var $ = require("jquery");
var BootstrapDialog = require("bootstrap3-dialog");

function ApiUser(frontend, _custom_on_auth_failed_handler) {
	this.state = {
		frontend: frontend,
		token: (require("cookies-js")).get("token"),
		pending_cache: {},
		finished_cache: {},
		custom_on_auth_failed_handler: (_custom_on_auth_failed_handler || null)
	};

	console.log(this.state);
}

ApiUser.prototype.set_custom_on_auth_failed_handler = function(new_handler) {
	console.log(new_handler);
	this.state.custom_on_auth_failed_handler = new_handler;
};

ApiUser.prototype.fetch_data_assume_logged_in = function(endpoint, args, cb) {
	var me = this;
	var state = this.state;
	var fe_state = this.state.frontend.state;

	if (!args) {
		var finished_cached = state.finished_cache[endpoint];
		if (typeof finished_cached != "undefined") {
			if (finished_cached !== null ) {
				cb(null, finished_cached);
			} else {
				cb(finished_cached, null);
			}
			return;
		} else {
			var pending_cached = state.pending_cache[endpoint];
			if (pending_cached) {
				pending_cached.push(cb);
				return;
			}
		}
	}

	state.pending_cache[endpoint] = [cb];

	let is_empty_data_ok = (args && args.empty_data_ok)

	$.ajax({
		url: fe_state.api_base + endpoint,
		method: "GET",
		dataType: "json",
		contentType: "application/json; charset=utf-8",
		beforeSend: function (xhr) {
			xhr.setRequestHeader ("Authorization", "Token " + state.token);
		},
		success: function(result) {

			var is_ok = (result && result.status && result.status == "ok" && (is_empty_data_ok ? 1 :result.data));

			if (is_ok) {


				let cbs = state.pending_cache[endpoint];
				delete state.pending_cache[endpoint];
				state.finished_cache[endpoint] = result.data;

				for (let cb_n of cbs) {
					(cb_n)(null, result.data);
				}

			} else {
				if (result.error) {
					var need_login_errors = [
						"token_expired",
						"token_expired",
						"session_expired",
						"session_not_found"
					];

					if ((need_login_errors.indexOf(result.error) != -1) && (!me.state.frontend.state.is_public_page) ) {
						console.log("Bad token")
						state.token = null;

						console.log(state.custom_on_auth_failed_handler);

						if (state.custom_on_auth_failed_handler) {
							state.custom_on_auth_failed_handler(result.error);
						} else {


							if ((endpoint == "/auth/get_features_config") && (document.location.pathname == "/video/course_enroll")) {
								return; // do nothing
							}

							window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
						}

						
					} else {
						// cb("Error fetching data: " + result.error, null);

						let cbs = state.pending_cache[endpoint];
						delete state.pending_cache[endpoint];
						state.finished_cache[endpoint] = null;

						for (let cb_n of cbs) {
							(cb_n)("Error: " + result.error, null);
						}


					}
				}
			}
		},
		error: function(err) {
			me.state.token = null;
			// (require("cookies-js")).set("token", null)

			// For now, don't show this for GET requests.

			// BootstrapDialog.show({
			// 	message: "We are sorry but your request can't be processed right now. Please try again later.",
			// 	title: "Error",
			// 	cssClass: "very-high-zindex",
			// 	type: BootstrapDialog.TYPE_WARNING,
			// 	buttons: [

			// 		{
			// 			label: "OK",
			// 			action: function(dialogItself){
			// 				dialogItself.close();
			// 			}
			// 		}
			// 	]
			// });


			cb("Error: " + err, null);
		}
	});
};

// TODO: ^ Merge these v

ApiUser.prototype.post_data_assume_logged_in = function(endpoint, data, cb) {
	var me = this;
	var state = this.state;
	var fe_state = this.state.frontend.state;

	$.ajax({
		url: fe_state.api_base + endpoint,
		method: "POST",
		dataType: "json",
		contentType: "application/json; charset=utf-8",
		data: JSON.stringify(data),
		beforeSend: function (xhr) {
			xhr.setRequestHeader ("Authorization", "Token " + state.token);
		},
		success: function(result) {

			var is_ok = (result && result.status && result.status == "ok" && result.data);

			if (is_ok) {
				cb(null, result.data);
			} else {
				if (result.error) {

					var need_login_errors = [
						"token_expired",
						"token_expired",
						"session_expired",
						"session_not_found"
					];

					if ((need_login_errors.indexOf(result.error) != -1) && (!me.state.frontend.state.is_public_page) ) {
						console.log("Bad token")
						state.token = null;

						if (state.custom_on_auth_failed_handler) {
							state.custom_on_auth_failed_handler();
						} else {
							window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
						}						
					} else {
						cb(result.error, null);
					}
				}
			}
		},
		error: function(err) {
			// me.state.token = null;
			// (require("cookies-js")).set("token", null)
			BootstrapDialog.show({
				message: "We are sorry but your request can't be processed right now. Please try again later.",
				title: "Error",
				cssClass: "very-high-zindex",
				type: BootstrapDialog.TYPE_WARNING,
				buttons: [

					{
						label: "OK",
						action: function(dialogItself){
							dialogItself.close();
							// window.location = window.location;
						}
					}
				]
			});
			cb(err, null);
		}
	});
};


ApiUser.prototype.fetch_data = function(endpoint, args, cb) {
	var me = this;

	if (!this.state.token && !this.state.frontend.state.is_public_page) {
		if (this.state.custom_on_auth_failed_handler) {
			this.state.custom_on_auth_failed_handler();
		} else {
			// window.location = "/login";
			window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
		}
	} else {
		me.fetch_data_assume_logged_in(endpoint, args, cb);
	}

};

ApiUser.prototype.fetch_data_promise = function(endpoint, args) {
	return new Promise((resolve, reject) => {
		this.fetch_data_assume_logged_in(endpoint, args, (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	});
}

ApiUser.prototype.unauthed_absolute_fetch = function(url, cb) {
  var me = this;
  var state = this.state;
  var fe_state = this.state.frontend.state;

  $.ajax({
    url: url,
    method: "GET",
    dataType: "json",
    contentType: "application/json; charset=utf-8",
    success: function(result) {
      cb(null, result);
    },
    error: function(err) {
      cb("Error: " + err, null);
    }
  });
};

ApiUser.prototype.unauthed_absolute_fetch_promise = function(url) {
	return new Promise((resolve, reject) => {
		this.unauthed_absolute_fetch(url, (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	});
}


ApiUser.prototype.post_data = function(endpoint, data, cb) {
	var me = this;

	if (!this.state.token && !this.state.frontend.state.is_public_page) {
		if (this.state.custom_on_auth_failed_handler) {
			this.state.custom_on_auth_failed_handler();
		} else {
			// window.location = "/login";
			window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
		}
	} else {
		me.post_data_assume_logged_in(endpoint, data, cb);
	}

};

ApiUser.prototype.post_data_promise = function(endpoint, data) {
	return new Promise((resolve, reject) => {
		this.post_data_assume_logged_in(endpoint, data, (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	});
}


module.exports = ApiUser;