"use strict";

var $ = require("jquery");

var BootstrapDialog = require("bootstrap3-dialog");

var common = require("../../common");

function fix_html_ents(str) {
  return str.replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<");
}

module.exports = class RecordsPage {
  constructor(frontend) {
    this.state = {
      frontend: frontend,
    };
  }

  add_form(button, all_lessons, cl_id) {
    let form = $("<form/>").addClass("file_upload_form");
    form.attr("action", this.state.frontend.state.api_base + "/student/records/upload_file");
    form.attr("method", "post");
    form.attr("enctype", "multipart/form-data");

    if (document.location.host == "portaltest2.jonathanaikennewman.com") {
      form.attr("target", "_asdf");
    }

    function discard_form() {
      $(form).remove();
      $(".records, .page-title").removeClass("hide");
    }

    let cancel_btn = $("<a/>")
      .addClass("btn green-haze")
      .css("margin-top", "10px")
      .attr("href", "#")
      .text(nss("103:btn_cancel_upload"));

    $(cancel_btn).on("click", (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      discard_form();
    });

    if (!cl_id) {
      form.append(cancel_btn);
    }

    form.append($("<h1/>").text(button.label));

    // let btn_index_field = $("<input/>").attr("type", "hidden").attr("name", "button_index").val(button.Index);
    // form.append(btn_index_field);

    const redirect_url = cl_id ? `/student/lesson_detail?lesson=${cl_id}` : document.location.href;

    let redirect_field = $("<input/>")
      .attr("type", "hidden")
      .attr("name", "redirect_uri")
      .val(redirect_url);
    form.append(redirect_field);

    let needval_fields = [];

    let submit_btn = $("<input/>")
      .addClass("btn green-haze disabled")
      .css("margin-top", "10px")
      .attr("type", "submit")
      .val(nss("103:btn_upload"));

    let maybe_show_or_hide_submit = (_) => {
      let ok = true;
      if (!file_field.val()) ok = false;

      for (let field of needval_fields) {
        let val = field.val();
        if (field.attr("name") == "lesson") {
          if (!val || val == "dummy") {
            ok = false;
          }
        } else {
          if (!val) ok = false;
        }
      }

      if (ok) {
        submit_btn.removeClass("disabled");
      } else {
        submit_btn.addClass("disabled");
      }
    };

    const lessons = all_lessons; //.filter(lesson => lesson.)

    for (let field_name of button.fields) {
      if (!["Lesson", "Title", "Grade", "Remark"].includes(field_name)) continue;
      let field_http_name = field_name.toLowerCase();
      if (field_http_name == "remark") {
        field_http_name = "remarks";
      }
      let field = null;
      if (button.title_list.length > 0 && field_name == "Title") {
        field = $("<select/>")
          .addClass("form-control upload_text_field")
          .attr("name", field_http_name);
        for (const opt of button.title_list) {
          $(field).append($("<option/>").val(opt).text(opt));
        }
      } else if (field_name == "Lesson") {
        if (lessons.length > 0 && _feature_config.features.includes("PorLessonFileUpload")) {
          field = $("<select/>")
          .addClass("form-control upload_text_field")
          .attr("name", field_http_name);
      
          if (cl_id) {
            $(field).attr("readonly", "readonly");
          } else {
            $(field).append($("<option/>").val("dummy").text(nss("records_field_lesson")));
          }
      
          for (const opt of lessons) {
            const name = fix_html_ents(
            `${opt.formatted_date} ${opt.formatted_time} - ${opt.cou_name} (${opt.cou_code})`
            );
            const elem = $("<option/>").val(opt.cl_id).text(name);
            if (cl_id && opt.cl_id == cl_id) {
            $(elem).attr("selected", "selected");
            }
            $(field).append(elem);
          }
        } else {
          continue;
        }
      } else {
        field = $("<input/>")
          .addClass("form-control upload_text_field")
          .attr("type", "text")
          .attr("name", field_http_name)
          .attr("placeholder", field_name);
      }
      if (field_http_name != "remarks") {
        needval_fields.push(field);
      }
      form.append(field);
      field.on("change", (_) => {
        maybe_show_or_hide_submit();
      });
    }

    let file_field = $("<input/>").attr("type", "file").attr("name", "upload_file");
    let accept_exts = ".jpg,.jpeg,.png,.gif,.pdf";
    file_field.attr("accept", accept_exts);
    form.append(file_field);

    file_field.on("change", (_) => {
      maybe_show_or_hide_submit();
    });

    $(form).on("submit", (ev) => {
      if ($(submit_btn).hasClass("disabled")) {
        ev.preventDefault();
        ev.stopPropagation();
        return;
      }

      // discard_form();
    });
    form.append(submit_btn);

    // add form to page
    $("#buttons_dummy").after(form);

    // hide the main profile page
    $(".records, .page-title").addClass("hide");
  }

  load(cb) {
    var api_user = this.state.frontend.state.api_user;
    let get_string = this.state.frontend.get_string.bind(this.state.frontend);

    let data = null;

    let filter_and_make_table = () => {
      let selected_year = $("#year-control").val();
      let selected_records = selected_year
        ? data.rows.filter((r) => {
            return r.filterable_year == selected_year;
          })
        : data.rows;
      common.build_table($("#records-table"), data.legend, selected_records);

      $(".data-span a").click(function (e) {
        if ($(window).width() <= 1160) return;

        let href = $(this).attr("href");
        let href_split = href.split(".");
        let last_part = href_split[href_split.length - 1].toLowerCase();
        if (["png", "jpg", "jpeg", "gif", "bmp"].indexOf(last_part) == -1) {
          return;
        }

        e.preventDefault();

        BootstrapDialog.show({
          message: $("<div/>")
            .addClass("record-popup-image")
            .css("background-image", "url(" + href + ")"),
          title: $(this).text(),
          cssClass: "very-high-zindex",
          type: BootstrapDialog.TYPE_INFO,
          buttons: [
            {
              label: "Close",
              action: function (dialogItself) {
                dialogItself.close();
              },
            },
          ],
        });
      });
    };

    api_user
      .fetch_data_promise("/student/records/get_all_records", null)
      .catch((e) => {})
      .then((_reports) => {
        console.log(_reports);
        data = _reports;

        if (data.rows.length < 1) {
          $(".msg-no-results").removeClass("hide");
          // cb();
          // return;
        }

        let years = new Set();
        data.rows.forEach((r) => {
          // r._row_link_url =;
          years.add(r.filterable_year);
        });
        let today_year = new Date().getFullYear();

        for (let year of years) {
          let val = year;
          let opt = $("<option/>").val(val).text(val);
          if (val == today_year) {
            $(opt).attr("selected", "selected");
          }

          $("#year-control").append(opt);
        }

        filter_and_make_table();

        $("#year-control").change(filter_and_make_table);

        const args = common.get_args();

        if (data.file_opts) {
          if (args.cl_id) {
            const cl_id = parseInt(args.cl_id);
            // TODO! This is exactly the wrong way to do this. Sorry. Time pressure.
            api_user
              .fetch_data_promise("/student/course/get_lesson_all?include_sessions=true", null)
              .then((lessons) => {
                lessons = lessons.filter((l) => l.cl_id == cl_id);
                for (let upload of [data.file_opts]) {
                  let btn = $("<a/>")
                    .addClass("btn green-haze")
                    .css("margin-top", "10px")
                    .attr("href", "#")
                    .text(upload.label);
                  $(btn).on("click", (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    this.add_form(upload, lessons, cl_id);
                  });
                  $("#upload_buttons").append(btn);
                  $(btn).click();
                  // <a style="margin-top: 10px;" href="/student/profile_details" class="btn green-haze" id="btn-details">Edit my Details</a>
                }
              });
          } else {
            api_user
              .fetch_data_promise(
                "/student/course/get_lesson_all?days_back=4&include_sessions=true",
                null
              )
              .then((lessons) => {
                for (let upload of [data.file_opts]) {
                  let btn = $("<a/>")
                    .addClass("btn green-haze")
                    .css("margin-top", "10px")
                    .attr("href", "#")
                    .text(upload.label);
                  $(btn).on("click", (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    this.add_form(upload, lessons);
                  });
                  $("#upload_buttons").append(btn);
                  // <a style="margin-top: 10px;" href="/student/profile_details" class="btn green-haze" id="btn-details">Edit my Details</a>
                }
              });
          }
        }

        cb();
      });
  }
};
