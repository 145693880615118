"use strict";

var $ = require("jquery");
var common = require("../../common");
var moment = require("moment");

var BootstrapDialog = require("bootstrap3-dialog");
const base64url = require("base64-url");

function LessonDetailPage(frontend) {
  this.state = {
    frontend: frontend,
  };
}

function isMobileDevice() {
	var isMobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any: function () {
          return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
          );
        },
      };

	  return isMobile.any();
}

function addDeleteMakeupBtn(api_user, clid) {
	$("#delete-makeup")
	.removeClass("disabled")
	.removeClass("hide")
	.click((ev) => {
	  ev.preventDefault();
	  $("#delete-makeup").addClass("disabled");

	  BootstrapDialog.show({
		message: "Are you sure you want to delete this Make-Up?",
		title: "Confirm Make-Up deletion",
		cssClass: "very-high-zindex",
		closable: false,
		type: BootstrapDialog.TYPE_INFO,
		buttons: [
		  {
			label: "Yes, Delete",
			action: function (dialogItself) {
			  api_user.post_data(
				"/student/attendance/delete_makeup",
				{
				  mk_clid: clid,
				},
				(err, res) => {
				  if (err) {
					let msg = "Failed to delete this Make-Up.";
					if (err == "makeup_attendance_marked") {
					  msg = nss("106:makeup_delete_error_makeup_attendance_marked");
					} else if (err == "makeup_fee_paid") {
					  msg = nss("106:makeup_delete_error_makeup_fee_paid");
					}

					BootstrapDialog.show({
					  message: msg,
					  title: "Failed to delete Make-Up",
					  cssClass: "very-high-zindex",
					  type: BootstrapDialog.TYPE_WARNING,
					  buttons: [
						{
						  label: "Close",
						  action: function (dialogItself) {
							dialogItself.close();
							window.location = window.location;
						  },
						},
					  ],
					});
				  } else {
					BootstrapDialog.show({
					  message: "Deleted this Make-Up.",
					  title: "Make-Up Deleted",
					  cssClass: "very-high-zindex",
					  type: BootstrapDialog.TYPE_INFO,
					  buttons: [
						{
						  label: "Close",
						  action: function (dialogItself) {
							dialogItself.close();
							window.location = "/student/makeups";
						  },
						},
					  ],
					});
				  }
				}
			  );

			  dialogItself.close();
			},
		  },

		  {
			label: "Cancel",
			action: function (dialogItself) {
			  $("#delete-makeup").removeClass("disabled");
			  dialogItself.close();
			},
		  },
		],
	  });
	});
}

function add_records(data) {
		common.build_table($("#records-table"), data.legend, data.rows);

		$(".data-span a").click(function(e) {

			if ($(window).width() <= 1160) return;

			let href = $(this).attr("href");
			let href_split = href.split(".");
			let last_part = href_split[href_split.length-1].toLowerCase();
			if (["png", "jpg", "jpeg", "gif", "bmp"].indexOf(last_part) == -1) {
				return;
			}

			e.preventDefault();

			BootstrapDialog.show({
				message: $("<div/>")
					.addClass("record-popup-image")
					.css("background-image", "url(" + href  + ")"),
				title: $(this).text(),
				cssClass: "very-high-zindex",
				type: BootstrapDialog.TYPE_INFO,
				buttons: [
					{
						label: "Close",
						action: function(dialogItself){
							dialogItself.close();
						}
					}
				]
			});
		});
}

LessonDetailPage.prototype.load_async = async function (cb) {
  var args = common.get_args();
  console.log(args);
  var clid = args["lesson"];
  if (!clid) {
    window.location = "/student/lessons";
  } else {
    $("#watch-my-videos").attr("href", "/student/watch_my_video?course_lesson=" + clid);

    var api_user = this.state.frontend.state.api_user;

    const lesson = await api_user.fetch_data_promise(
      "/student/course/get_lesson_one/" + clid,
      null
    );

    const lesson_and_att_photos = await api_user.fetch_data_promise(
      "/student/course/get_lesson_and_att_photos?clid=" + clid,
      null
    );
    
    let records = null;
    if (_feature_config.features.includes("PorRecords")) {
      records = await api_user.fetch_data_promise("/student/records/get_all_records?cl_id=" + clid, null);
    }

    console.log(lesson);

    let tr = $("<tr/>");

    [
      lesson.date_fmt + ", " + lesson.start_time_fmt + " - " + lesson.end_time_fmt,
      fix_html_ents(lesson.cou_name),
      fix_html_ents(lesson.cou_code),
      lesson.venue,
      lesson.teacher,
      lesson.class_type,
      lesson.attendance,
      lesson_and_att_photos.att_photos,
      lesson.in_time,
      lesson.out_time,
      lesson.lesson_remark,
      lesson.student_remark,
      lesson.not_come_remark,
      lesson.teacher_remark,
      lesson_and_att_photos.lesson_photos,
    ].forEach((to_add, to_add_i) => {
      let td = $("<td/>");

      if (to_add_i == 7 || to_add_i == 14) {
        let contain = $("<div/>").addClass("imgContainCol");
        for (let image of to_add) {
          let link = $("<a/>").attr("href", image).attr("target", "_blank").addClass("imgLink");

          link.on("click", (ev) => {
            ev.preventDefault();
            $(".full-screen-image").css("background-image", "url(" + image + ")");
            $(".full-screen-blackout").css("display", "flex");
          });

          link.css("background-image", "url(" + image + ")");
          contain.append(link);
        }
        $(td).append(contain);
      } else {
        td.text(to_add);
      }

      $(tr).append(td);
    });

    let css_for_col = function (col_num, option_name, string_suffix) {
      return `
	        .lesson-detail-table-wrapper td:nth-of-type(${col_num})  {
	          ${lesson.show_fields.includes(option_name) ? "" : "display: none;"}
	        }
	        .lesson-detail-table-wrapper td:nth-of-type(${col_num}):before {
	          content: "${nss("104:label_lesson_detail_" + string_suffix)}"
	        }
	        `;
    };

    common.add_css(
      "" +
        css_for_col(1, "lesson_datetime", "Date_Time") +
        css_for_col(2, "cou_name", "Course_Name") +
        css_for_col(3, "cou_code", "Course_Code") +
        css_for_col(4, "venue", "Venue") +
        css_for_col(5, "teacher", "Teacher") +
        css_for_col(6, "enroll_type", "Type") +
        css_for_col(7, "attendance", "Attendance") +
        css_for_col(8, "attendance", "Attendance_Photos") +
        css_for_col(9, "clock_in", "Clock_In_Time") +
        css_for_col(10, "clock_out", "Clock_Out_Time") +
        css_for_col(11, "lesson_remarks", "Lesson_Remarks") +
        css_for_col(12, "student_remarks", "Student_Remarks") +
        css_for_col(13, "notcoming_remarks", "Not_Coming_Remarks") +
        css_for_col(14, "teacher_remarks", "Teacher_Remarks") +
        css_for_col(15, "lesson_photos", "Lesson_Photos")
    );

    $("#lesson-detail-table tbody").append(tr);

    if (
      lesson.attachments &&
      lesson.attachments.length > 0 &&
      lesson.show_fields.includes("lesson_attachments")
    ) {
      $("#attachments-header").removeClass("hide");
      lesson.attachments.forEach((attachment) => {
        let url =
          this.state.frontend.state.api_base +
          "/student/course/download_course_attachment?clid=" +
          clid +
          "&file_id=" +
          attachment.id;
        $(".attachments").append(
          $("<a/>")
            .addClass("attachment")
            .attr("target", "_blank")
            .attr({
              href: url /*"target": "_blank"*/,
            })
            .text(attachment.name)
            .click((e) => {})
        );
      });
    }

    if (lesson.videos && lesson.videos.length > 0) {
      $("#videos-header").removeClass("hide");

      let video_sort_type = args["video_sort_type"] || "idt_asc";

      switch (video_sort_type) {
        case "idt_asc":
          lesson.videos = lesson.videos.sort((a, b) =>
            a.internal_date_time.localeCompare(b.internal_date_time)
          );
          break;

        case "idt_desc":
          lesson.videos = lesson.videos.sort((a, b) =>
            b.internal_date_time.localeCompare(a.internal_date_time)
          );
          break;

        case "simple_reverse":
          lesson.videos = lesson.videos.reverse();
          break;

        default:
          break;
      }

      console.log(lesson.videos);

      let videos_flat = lesson.videos.map((lv) => ({
        f_id: lv.f_id,
        cl_id: lv.cl_id,
        name: lv.name,
      }));
      let base_watch_url = "/student/watch_my_video?course_video_data=";

      console.log("Flat", videos_flat);

      lesson.videos.forEach((video) => {
        // let url = video.url;
        let text = video.name;
        if (!text || text == "") {
          text = "Watch";
        }

        let data = {
          flat: videos_flat,
          index_into_flat: videos_flat.findIndex((f) => {
            return f.cl_id == video.cl_id && f.f_id == video.f_id;
          }),
          return_url: document.location.href,
        };

        let b64 = base64url.encode(JSON.stringify(data));

        let url = base_watch_url + b64;

        $(".videos").append(
          $("<a/>").addClass("video").attr({ href: url /*"target": "_blank"*/ }).text(text)
        );
      });
    }

    if (lesson.broadcast_link && lesson.broadcast_link.indexOf("://") != -1) {
      $("#broadcast-header").removeClass("hide");
      $("#video-link").attr("href", lesson.broadcast_link).removeClass("hide");
    }

    const start_zoom_meeting = (_) => {
      console.log(lesson.zoom_response);
        window.location = lesson.zoom_response.joinUrl;
    };

    if (lesson.zoom_response) {
      $("#zoom-btn")
        .removeClass("hide")
        .text(nss("106:lesson_detail_zoom_join"))
        .click((_) => {
          $("#zoom-btn").attr("disabled", "disabled");
          start_zoom_meeting();
        });
    } else if (lesson.zoom_websocket_url && lesson.zoom_websocket_info) {
      $("#zoom-note").removeClass("hide");

      const ws = new WebSocket(lesson.zoom_websocket_url, [lesson.zoom_websocket_info.token]);

      ws.onopen = (_) => {
        ws.send(
          JSON.stringify({
            type: "wait",
            uuid: `zoom_lesson_${lesson.zoom_websocket_info.a_id}_${clid}`,
          })
        );
      };

      ws.onmessage = (evt) => {
        console.log("onmessage:", evt);
        const result = JSON.parse(evt.data);
        if (result.action === "started") {
          console.log("started");
          window.location = window.location;
        }
      };

      ws.onclose = (evt) => {
        console.log("ws closed");
        console.log(evt);
      };

      ws.onerror = (evt) => {
        console.log("ws err");
        console.log(evt);
      };
    } else if (lesson.zoom_error) {
      console.log(lesson.zoom_error);
      $("#zoom-note").removeClass("hide");
    } else {
      // no Zoom?
      $("#zoom-note").removeClass("hide");
    }

    if (!lesson.show_addons) {
      $(".videos, .attachments, #videos-header, #attachments-header").addClass("hide");
    }

    if (lesson.is_withdrawn) {
      $(".page-title").append(
        $("<span/>").text(this.state.frontend.get_string("withdrawn")).addClass("expired-note")
      );
    } else if (lesson.is_expired) {
      $(".page-title").append(
        $("<span/>").text(this.state.frontend.get_string("expired")).addClass("expired-note")
      );
    } else {
      if (lesson.can_skip && !lesson.is_makeup) {
        $("#makeup-lesson")
          .removeClass("disabled")
          .attr(
            "href",
            "/student/lessons?mode=add_makeup_to&lesson_from=" + clid // + "&course=" + lesson.cou_id
          );
      } else if (lesson.was_skipped) {
        $("#makeup-lesson").text("Lesson Skipped");
      } else {
        $("#makeup-lesson").addClass("hide");
      }

      if (lesson.is_makeup && lesson.can_delete_makeup) {
        addDeleteMakeupBtn(api_user, clid);
      } else {
        $("#delete-makeup").addClass("hide");
      }

      if (lesson.can_postpone) {
        $("#postpone-lesson").removeClass("hide").attr("href", "/jdp2?page=postpone&clid=" + clid);
      }
    }

	if (records && records.rows.length > 0) {
		$("#files-header, .files").removeClass("hide");
		add_records(records);

	}


	$("#upload-file-btn").removeClass("disabled").click(e => {
		e.preventDefault();
		window.location.href = "/student/records?cl_id=" + clid;
	});

    $(".full-screen-blackout, .full-screen-exit").click((e) => {
      e.preventDefault();
      $(".full-screen-image").css("background-image", "none");
      $(".full-screen-blackout").css("display", "none");
      $(".full-screen-info").empty();
    });
  }
};

LessonDetailPage.prototype.load = function (cb) {
  this.load_async().then((_) => {
    cb();
  });
};

module.exports = LessonDetailPage;
