"use strict";

var $ = require("jquery");
var async = require("async");
var common = require("../../common");
var moment = require("moment");

module.exports = class MakeupConfirmPage {

	constructor (frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load (cb) {

		let args = common.get_args();
		let from_clid = args.lesson_from;
		let to_clid = args.lesson_to;

		var api_user = this.state.frontend.state.api_user;

		let url_base = "/student/course/get_lesson_one/";
		let url_base_v1 = "/student/course/get_lesson_one_v1/";

		let back_target = "/student/lessons?mode=add_makeup_to&lesson_from=" + from_clid + ( args.course ? ("&course=" + args.course) : "");
		$("#go-back-btn").attr("href", back_target);

		let lesson_to_html = l => {
			let parts = [
				l.cou_code ? {title: "Course Code", val: fix_html_ents(l.cou_code)} : null,
				{title: "Course Name", val: fix_html_ents(l.cou_name)},
				l.branch ? {title: "Branch", val: l.branch} : null,
				{title: "Date", val:        l.date_fmt},
				{title: "Time", val:        l.start_time_fmt},
			].filter(p => p !== null);

			let result = $("<div/>");

			for (let part of parts) {
				let line = $("<div/>");
				$(line).append($("<h5/>").text(part.title + ": "));
				$(line).append($("<span/>").text(part.val));
				$(result).append(line);
			}

			return result;
		};



		async.parallel({
			from: (cb) => { api_user.fetch_data(url_base + from_clid + "?is_makeup_confirm_mode=1", null, cb); },
			to:   (cb) => { api_user.fetch_data(url_base_v1 + to_clid,   null, cb); }
		}, (err, results) => {

			$("#makeup-from-info").append(lesson_to_html(results.from));
			$("#makeup-to-info").append(lesson_to_html(results.to));

			$("#confirm-makeup-btn").click(e => {
				e.preventDefault();
				$("#confirm-makeup-btn").addClass("disabled");

				let makeup_data = {
					from_lesson_id: from_clid,
					to_lesson_id:   to_clid
				};

				let remark_val = $("#remarks").val();
				if (remark_val) {
					makeup_data.remark = remark_val;
				}

				api_user.post_data("/student/attendance/add_makeup", {
					makeup: makeup_data
				}, (err, res) => {
					console.log(err);
					console.log(res);
					if (err) {
						if (["too_late", "max_student_count", "makeuplesson_already_had", "skiplesson_already_had"].indexOf(err) == -1) {
							err = "generic_error_occurred"
						} else {
							err = "makeup_error_" + err;
						}
						err = this.state.frontend.get_nss_from_sid("106:" + err);
						$(".error-detail").text(err);
						$(".bad").removeClass("hide");
					} else {
						$(".ok").removeClass("hide");
						$("#go-back-btn").text("Done").attr("href", "/student/makeups");
					}
				});

			});

			let show_remarks = this.state.frontend.state.feature_config.makeup_remarks_required;
			if (show_remarks) {
				$("#remarks-part").removeClass("hide");
			} else {
				$("#confirm-makeup-btn").removeClass("disabled");
			}

			// $("#confirm-makeup-btn").removeClass("disabled");
			$("#remarks").removeAttr("disabled").on("keyup change", _ => {
				if ($("#remarks").val()) {
					$("#confirm-makeup-btn").removeClass("disabled");
				} else {
					$("#confirm-makeup-btn").addClass("disabled");
				}
			});

			cb();


		});


		// api_user.fetch_data("/student/attendance/get_makeup_all", null, function(err, makeups) {

		// 	console.log(makeups);
			
		// 	for (let makeup of makeups) {
		// 		let tr = $("<tr/>");
		// 		var target = "/student/lesson_detail?lesson=" + makeup.cl_id;

		// 		let date = new Date(makeup.cl_realdate);

		// 		[
		// 			date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate()),
		// 			makeup.cl_starttime,
		// 			makeup.cou_name,
		// 			makeup.cou_code
		// 		].forEach(to_add => {
		// 			$(tr).append(generate_linked_cell(to_add, target));
		// 		});

		// 		$("#makeups-table tbody").append(tr);
		// 	}

		// 	cb();
		// });


	}

};