"use strict";

var $ = require("jquery");
var ApiUser = require("../../ApiUser");
var Cookies = require("cookies-js");
var common = require("../../common");

function LoginPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

Date.prototype.addDays = function (d) {
    if (d) {
        var t = this.getTime();
        t = t + (d * 86400000);
        this.setTime(t);
    }
};

LoginPage.prototype.attempt_login = function(id, password, cb) {
	var state = this.state;
	var fe_state = this.state.frontend.state;

	$.ajax({
		url: fe_state.api_base + "/auth/login",
		method: "POST",
		dataType: "json",
		contentType: "application/json; charset=utf-8",

		data: JSON.stringify({username: id, password: password }),
		success: results => {
			if (results.status != "ok") {
				cb("Received error: " + results.error);
			} else {
				console.log(results.data);
				if (results.data.is_new) {
					Cookies.set("is_new", true,  {expires: new Date("9999-09-09")});
				} else {
					Cookies.expire("is_new");
				}
				Cookies.set("token", results.data.token, {expires: 60*60*24*7 });
				Cookies.set("user_id", id);
				Cookies.expire("did_recalc");
				Cookies.expire("last_video_id");
				cb(null);
			}
		},
		error: err => {
			cb("Error logging in: " + JSON.stringify(err));
		} 
	});
};

LoginPage.prototype.load = function(cb) {
	var me = this;
	console.log("Loaded");

	Cookies.expire("token");
	Cookies.expire("is_new");
	Cookies.expire("intro_understood");

	var api_user = this.state.frontend.state.api_user;
	


	let args = common.get_args();

	if (args.activation_token) {
		$(".login-form").addClass("hide");

		api_user.post_data("/auth/activate_account", {activation_token: args.activation_token}, (err, res) => {
			if (err) {
				alert("An error occurred attempting to activate your account.\n\nPlease check whether your account has been activated already by logging into the system. If you are still unable to login, contact your administrator.");
				window.location = "/login";
			} else {
				alert("Thank you for registering! Your account has been activated and you can now log in.");

				let redirect_loc = Cookies.get("redirect_after_create_account");
				if (redirect_loc) {
					Cookies.expire("redirect_after_create_account");
					window.location = "/login?return=" + redirect_loc;
				} else {
					window.location = "/login";
				}
			}
		});
	} else {
		api_user.fetch_data("/auth/get_login_details", null, (err, message_data) => {

			console.log(message_data);

			$("#guest-btn").attr("href", "/api/auth/redirect_new_user");

			$(".login-form").submit(function(e) {
				e.preventDefault();
				me.attempt_login($("[name='username']").val(), $("[name='password']").val(), function(err) {
					if (err) {
						console.log("Login error: " + err);
						if (err.includes("bad_role")) {
							$("#incorrect-role").css("display", "block");
						} else {
							$("#incorrect-pw").css("display", "block");
						}
						
					} else {
						let new_api_user = new ApiUser(me.state.frontend);

						new_api_user.fetch_data("/auth/get_features_config?cachebust=" + Date.now(), null, (err, feature_config) => {
								if (feature_config.must_change_password) {
									window.location = "/request_password_reset?mode=confirm&variant=first_login";
								} else {
									
									if (args.return) {
										window.location = document.location.protocol + "//" + document.location.host + decodeURIComponent(args.return);
									} else if (Cookies.get("is_new")) {
										// window.location = message_data.new_user_url;
										window.location = "/api/auth/redirect_new_user";


									} else {
										// window.location = (me.state.frontend.state.feature_config.features.includes("HideCalendar")) ? "/student/lessons" : "/student/calendar";
										window.location = message_data.existing_user_url;
									}
								}
						});
					}
				});
			});
		});
	}

	cb();
};

module.exports = LoginPage;
