"use strict";

var $ = require("jquery");
var ApiUser = require("../../ApiUser");
var Cookies = require("cookies-js");
var common = require("../../common");

var BootstrapDialog = require("bootstrap3-dialog");


var GLOBAL_has_shown_message = false;
var GLOBAL_has_shown_student_message = false;

var login_error = err => {
	console.log("login_error");
	if (GLOBAL_has_shown_message) return;
	GLOBAL_has_shown_message = true;

	BootstrapDialog.show({
		message: window.nss("105:need_login"),
		title: window.nss("105:need_login_title"),
		cssClass: "very-high-zindex",
		type: BootstrapDialog.TYPE_INFO,
		closable: false,
		buttons: [
			{
				label: window.nss("105:need_login_btn_login"),
				action: function(dialogItself){
					dialogItself.close();
					window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
				}
			},
			{
				label: window.nss("105:need_login_btn_create"),
				action: function(dialogItself) {
					dialogItself.close();
					Cookies.set("redirect_after_create_account", encodeURIComponent(document.location.pathname + document.location.search), {expires: new Date("9999-09-09")})
					window.location = "/create_account"
				}
			},
			// {
			// 	label: this.state.frontend.get_string("cancel"),
			// 	action: function(dialogItself) {
			// 		window.history.back();
			// 	}
			// }
		]
	});

};

var student_error = _ => {

	if (GLOBAL_has_shown_student_message) return;
	GLOBAL_has_shown_student_message = true;

	BootstrapDialog.show({
		message: "You need to register as a student before enrolling on a course.",
		title: "Student Registration",
		cssClass: "very-high-zindex",
		type: BootstrapDialog.TYPE_INFO,
		closable: false,
		buttons: [
			{
				label: "OK",
				action: function(dialogItself) {
					dialogItself.close();
					window.location = "/student/register_student?redirect_to_url=" + encodeURIComponent(document.location.pathname + document.location.search);
				}
			},
			// {
			// 	label: this.state.frontend.get_string("cancel"),
			// 	action: function(dialogItself) {
			// 		window.history.back();
			// 	}
			// }
		]
	});

};

function CourseEnrollPage(frontend) {
	console.log("ctor_1");
	this.state = {
		frontend: frontend
	};

	// let has_shown_message = false;
	console.log("ctor_2");
	this.state.frontend.state.api_user.set_custom_on_auth_failed_handler(err => {

		// if (has_shown_message) return;
		// has_shown_message = true;
		console.log("failed_handler");
		login_error(err);

		// BootstrapDialog.show({
		// 	message: this.state.frontend.get_string("need_login"),
		// 	title: this.state.frontend.get_string("need_login_title"),
		// 	cssClass: "very-high-zindex",
		// 	type: BootstrapDialog.TYPE_INFO,
		// 	closable: false,
		// 	buttons: [
		// 		{
		// 			label: this.state.frontend.get_string("need_login_btn_login"),
		// 			action: function(dialogItself){
		// 				dialogItself.close();
		// 				window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
		// 			}
		// 		},
		// 		{
		// 			label: this.state.frontend.get_string("need_login_btn_create"),
		// 			action: function(dialogItself) {
		// 				dialogItself.close();
		// 				Cookies.set("redirect_after_create_account", encodeURIComponent(document.location.pathname + document.location.search), {expires: new Date("9999-09-09")})
		// 				window.location = "/create_account"
		// 			}
		// 		},
		// 		// {
		// 		// 	label: this.state.frontend.get_string("cancel"),
		// 		// 	action: function(dialogItself) {
		// 		// 		window.history.back();
		// 		// 	}
		// 		// }
		// 	]
		// });

	});
	console.log("ctor_3");

}

CourseEnrollPage.prototype.special_test = function() {
	console.log("special_test1");
}

function show_error(str) {
	$("#agree-area").addClass("hide");
	$("#signup-error").removeClass("hide");
}

function show_error_2(str) {
	$("#agree-area").addClass("hide");
	$("#enroll-btn").addClass("hide");
	$("#signup-error").removeClass("hide");
	$("#signup-error").text(str);
}


CourseEnrollPage.prototype.load = function(cb) {
	console.log("load_1");
	var _api_user = this.state.frontend.state.api_user;
	console.log(_api_user);
	// var _api_user = new ApiUser(this.state.frontend, _ => {
	// 	console.log("failed_handler_2");
	// 	login_error();
	// });


	// _api_user.state.custom_on_auth_failed_handler = 

	let strings = this.state.frontend.state.res.course_enroll.en;
	let get_string = this.state.frontend.get_string.bind(this.state.frontend);

	$("#quick-access").hide();
	$(".page-content").removeClass("col-sm-8").addClass("col-sm-12");

	var args = common.get_args();

	let mode = args["mode"];
	let c_id = parseInt(args["id"]);

	let ent = parseInt(args["ent"]);

	if ((typeof ent == "undefined" || isNaN(ent)) && (!mode)) {
		$(".page-title").text(strings.error_no_ent);
		return;
	}

	if (!mode) {

		// let has_shown_message = false;

		// this.state.frontend.state._api_user.state.custom_on_auth_failed_handler = _ => {

		// 	if (has_shown_message) return;
		// 	has_shown_message = true;

		// 	BootstrapDialog.show({
		// 		message: this.state.frontend.get_string("need_login"),
		// 		title: this.state.frontend.get_string("need_login_title"),
		// 		cssClass: "very-high-zindex",
		// 		type: BootstrapDialog.TYPE_INFO,
		// 		closable: false,
		// 		buttons: [
		// 			{
		// 				label: this.state.frontend.get_string("need_login_btn_login"),
		// 				action: function(dialogItself){
		// 					dialogItself.close();
		// 					window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
		// 				}
		// 			},
		// 			{
		// 				label: this.state.frontend.get_string("need_login_btn_create"),
		// 				action: function(dialogItself) {
		// 					dialogItself.close();
		// 					window.location = "/create_account"
		// 				}
		// 			}
		// 		]
		// 	});

		// };

		_api_user.fetch_data("/video/courses/check_is_student", null, (err, res2) => {
			if (err) {
				// check_is_student is an authed endpoint, so we will assume any error
				// means an auth failure
				console.log("redirect to register_student");
				// window.location = "/student/register_student?redirect_to_url=" + encodeURIComponent(document.location.pathname + document.location.search);
				login_error(err);
			} else {
				console.log("check_is_student");
				console.log(res2);
				if (res2 === "yes") {

					let maybe_date = (args["date"]) ? ("&date=" + args["date"]) : "";

					_api_user.fetch_data("/video/courses/get_course_info?id=" + c_id + "" + maybe_date, null, function(err, res) {
						console.log("get_course_info");
						console.log(res);

						// $("#course-title").text(res.cou_name);
						

						let course_args = {
							id: c_id,
							ent: ent
						};

						if (res["join_date"]) {
							let ymd = res.join_date.split("-");
							let mdy = [ymd[1], ymd[2], ymd[0]].join("/");

							// course_args.joinDate = mdy;
							course_args.joinDate = res.join_date;
						}

						let add_data_point = (string, value, is_array) => {
							let div = $("<div/>").addClass("product-info-piece");
							let h4 = $("<h4/>").text(strings[string]);
							div.append(h4);

							if (is_array) {
								if (value) {
									value.forEach(v => {
										let span = $("<span/>").text(v);
										div.append(span);
									});
								}
							} else {
								let span = $("<span/>").text(value);
								div.append(span);
							}


							

							$("#product-data").append(div);
							return div;
						};

						let wanted_date_found = false;


						let add_start_date_dropdown_data_point = (string, values, current) => {
							let div = $("<div/>").addClass("product-info-piece");
							let h4 = $("<h4/>").text(strings[string]);
							div.append(h4);

							let select = $("<select/>");



							values.forEach(v => {

								let option = $("<option/>").text(v.display).val(v.value);
								if (current == v.value) {
									$(option).attr("selected", "selected");
									wanted_date_found = true;
								}
								$(select).append(option);

							});

							let on_change = (value) => {
								args["date"] = value;

								let args_array = [];
								for (let key in args) {
									args_array.push(key + "=" + args[key]);
								}

								console.log(args_array);

								let first_arg = args_array.shift();

								let query_string = "?" + first_arg;

								if (args_array.length > 0) {
									query_string += "&" + args_array.join("&");
								}

								window.location = window.location.pathname + query_string;
							}

							$(select).change(function() {
								on_change(this.value);
							});

							div.append(select);


							

							$("#product-data").append(div);


							if (!wanted_date_found) {
								console.log("change_0");
								on_change(values[0].value);
							}

							return div;
						};

					


						add_data_point("course_code", res.cou_code);
						add_data_point("course_title", res.cou_name);
						if (res.lesson_dates) {
							add_start_date_dropdown_data_point("start_date", res.lesson_dates, res.join_date);
						} else {

							show_error_2("This course has no available lessons to sign up for.");
							return;
						}
						

						_api_user.post_data("/student/fees_rewrite/get_fee_onlinereg", {
							course: [
								course_args
							]
						}, function(err, price_info) {


							if (err) {
								console.log("ERR: " + err);


								if (err == "course_already_enrolled") {
									// already_enrolled
									show_error();
								}

								return;
							}

							console.log(price_info);





							// add_data_point("start_date", res.cou_sdate);
							
							// add_data_point("end_date", res.end_date);
							add_data_point("fee_type", res.cft_name);
							// add_data_point("num_lessons", res.num_lessons);

							add_data_point("fees", price_info.items.map(i => {
								return i.typeName + ": " + window.currency + i.amount.toFixed(2)
							}), true);



							add_data_point("price", [
								strings["price2"] + ": " + window.currency + + price_info.totalDue.toFixed(2),
								strings["tax"]    + ": " + window.currency + price_info.taxDue.toFixed(2)
							], true);

							console.log("Is free: " + (price_info.totalAmount == 0));

							add_data_point("total", "" + window.currency + price_info.totalAmount.toFixed(2));



							$("#enroll-btn").click(e => {
								e.preventDefault();

								if (!$("#agree").is(":checked")) {
									alert(get_string("must_agree"));
									return;
								}


								$("#enroll-btn").addClass("disabled");


								let base_url = window.location.href.split("/").splice(0,3).join("/");

								let ymd = res.join_date.split("-");
								let mdy = [ymd[1], ymd[2], ymd[0]].join("/");

								_api_user.post_data(
									"/student/fees_rewrite/collectFee_course",
									{
										course: [{
											id: c_id,
											ent: ent,
											// joinDate: mdy
											joinDate: res.join_date

										}],
										returnUrl: base_url + "/video/course_enroll?mode=paypal_return&id=" + c_id,
										cancelUrl: base_url + "/video/course_enroll?mode=paypal_cancel",
									},
									(err, payment_req_res) => {
										console.log(err);
										console.log(payment_req_res);

										if (!err && !payment_req_res.href && payment_req_res.method && (price_info.totalAmount == 0)) {
											// Course is free to enroll in, and "payment" was successful.
											window.location = "/video/course_enroll?mode=paypal_return&force_success=1&id=" + c_id;
										} else if (err) {
											console.log("__err__");
											console.log(err);
											$(".page-title").text(strings.payment_error);
											$("#product-data, #agree-area, #enroll-btn").hide();
											$(".payment-error").removeClass("hide");
											$(".payment-error-code").text(JSON.stringify(err));
											window.scrollTo(0, 0);
										} else if (payment_req_res && payment_req_res.method == "DONE") {
											$(".payment-error").text(window.nss("106:enroll_err_enrolled_but_payment_failed"));
											$(".payment-error").removeClass("hide");
											$(".page-title").text("");
											$("#product-data, #agree-area, #enroll-btn").hide();
										} else if (payment_req_res && payment_req_res.href) {
											window.location = payment_req_res.href;
										} else {
											console.log("???");
											// TODO
											// This would be a good place to throw something the global error message handler can catch.
										}

										
									}
								);

							}).removeClass("disabled");

							cb();

						});



					});

				} else {
					console.log("register_student");
					student_error();
					// window.location = "/student/register_student?redirect_to_url=" + encodeURIComponent(document.location.pathname + document.location.search);
				}
			}
		});






	} else if (mode == "paypal_return") {
		$(".page-title").text(strings.paypal_wait);
		$("#enroll-btn, #agree-area").addClass("hide");

		let order_no = args["orderNo"];
		let payment_id = args["paymentId"];
		let payer_id = args["PayerID"];

		var is_force_ok = (args.force_success);

		let process_return_result = (err, payment_execute_res) => {
			console.log(err);
			console.log(payment_execute_res);

			if (err) {
				$(".page-title").text(strings.payment_error);
			} else {
				// $(".page-title").text(strings.thank_you);

				$(".page-title").text("");


				_api_user.fetch_data("/video/courses/get_thank_you_message/", null, function(err, thankyou) {

					$("#thank-you-area").html(thankyou);

				});


				let pil = $("#payment-info-link");
				if (!pil.hasClass("hide-because-lacks-feature")) {
					pil.removeClass("hide");
				}
				
				_api_user.fetch_data("/video/courses/get_course_info/" + c_id, null, function(err, gci_res) {
					console.log(gci_res);

					let data_div = $("<div/>").text(
						strings.course_enroll_done_info
						.replace("$COURSE_NAME", gci_res.cou_name)
						.replace("$FIRST_LESSON_DATE", gci_res.cou_sdate)
					);

					// TODO: Should this be hidden for free courses?
					// let past_link = $("<a/>").attr("href", "/student/fees_past").text(strings.course_enroll_done_info_link_name);

					// $(data_div).html($(data_div).html().replace("$PAST_PAYMENT_RECORDS_LINK", $(past_link).wrapAll("<div/>").parent().html()    ));

					$("#product-data").append(data_div);

				});

			}

			cb();

		}

		if (is_force_ok) {
			// Already checked OK (i.e. a free course). Don't ask PayPal
			process_return_result(null, {});
		} else {
			_api_user.fetch_data(
				"/student/fees_rewrite/executePaypalCheckout_onlinereg/" + order_no + "/" + payment_id + "/" + payer_id,
				{
					cachebust: true,
					empty_data_ok: true
				},
				process_return_result
			);	
		}




		

	} else if (mode == "paypal_cancel") {
		$(".page-title").text(strings.paypal_cancel);
		$("#enroll-btn").addClass("hide");






		let order_no = args["orderNo"];

		_api_user.fetch_data(
			"/student/fees_rewrite/cancelPaypalCheckout_onlinereg/" + order_no,
			{
				cachebust: true,
				empty_data_ok: true
			},
			(err, payment_execute_res) => {
				console.log(err);
				console.log(payment_execute_res);

				if (err) {
					$(".page-title").text(strings.payment_error);
				} else {
					$(".page-title").text(strings.paypal_cancel);
				}

				cb();

			}
		);









	} else {
		// ?
	}
	



};

module.exports = {_exported_class: CourseEnrollPage, custom_on_auth_failed_handler: err=> {
	login_error(err);
}};
